function init_tabs() {
    var $tabs = $('.js-tabs');
    var $active_labels = $('.tabs__label.active');

    // Show the right content onload
    $active_labels.each(function() {
        var $this = $(this);
        var target_tab = $this.data('tab-id');

        if(target_tab) {
            switch_tab($this, target_tab);
        }
    });

    // Tab label handler
    $tabs.find('.tabs__label').on('click', function() {
        var $this = $(this);
        var target_tab = $this.data('tab-id');

        if(target_tab) {
            switch_tab($this, target_tab);
        }
    });

    // Switch to tab (current clicked tab label + target tab id)
    function switch_tab($current_tab, tab_id) {
        if($current_tab && tab_id) {
            // Get target tab instance
            var $current_tabs = $current_tab.closest('.js-tabs');

            // Get targeted tab label + content
            var $target_label = $current_tabs.find("[data-tab-id='" + tab_id + "']");
            var $target_content = $current_tabs.find("[data-tab-content='" + tab_id + "']");

            // Hide current open tab
            $current_tabs.find('.tabs__label').removeClass('active');
            $current_tabs.find('.tabs__content').removeClass('active');

            // Show targeted tab
            $target_label.addClass('active');
            $target_content.addClass('active');
        }
    }
}