function init_header() {
    // Add handler to open menu
    $('.js-toggle-header').on('click', function() {
        $('body').toggleClass('header-active');
    });

    function isMac() {
        return navigator.platform.indexOf('Mac') > -1;
    }

     if(isMac()) {
        $('body').addClass('macos');
     }

     if($(".hero").length) {
        var header_height = 68;
        var hero_height = $(".hero").outerHeight(); // Hero height
        var total_offset = header_height + hero_height - 120;

        if($(window).scrollTop() > total_offset) {
            $('body').removeClass('transparent-header');
        }

        $(window).scroll(function() {
            if($(window).scrollTop() > total_offset) {
                $('body').removeClass('transparent-header');
            }
            else{
                $('body').addClass('transparent-header');
            }
        });
     }
}

function init_lang_picker() {
    $('.js-toggle-lang-chooser').on('click', function() {
        $('body').toggleClass('lang-chooser-active');
    });
}