function init_scroll_functions() {
    $('.js-scroll-button').on('click', function() {
        scroll_to_top();
    });
}

// Scrolls to given id (without #)
function scroll_to_element(target_id) {
    var $target = $('#' + target_id);
    var speed = 500;

    if ($target.length) {
      $('html, body').animate({
        scrollTop: parseInt($target.offset().top - 130)
      }, speed);
    }
    else {
      console.warn('Target id #' + target_id + ' not found!');
    }
}

// Scrolls to given class (without #)
function scroll_to_element_class(target_class) {
  var $target = $('.' + target_class);
  var speed = 500;

  if ($target.length) {
    $('html, body').animate({
      scrollTop: parseInt($target.offset().top - 130)
    }, speed);
  }
  else {
    console.warn('Target class #' + target_class + ' not found!');
  }
}

function scroll_to_top() {
    $('html, body').animate({scrollTop: '0px'}, 500);
}