function init_cookie_bar() {
    // Read existing cookie if exists
    var cookies_allowed = read_cookie('cookies_allowed');

    // If user has not accepted cookies yet
    if(cookies_allowed != 'true') {
        $('body').addClass('cookies-disabled');
    }

    // Enable cookies button
    $('.js-enable-cookies').on('click', function() {
        create_cookie('cookies_allowed', 'true', 365);
        $('body').removeClass('cookies-disabled');
    });

    // Disable cookies button
    $('.js-disable-cookies').on('click', function() {
        erase_cookie('cookies_allowed');
        $('body').addClass('cookies-disabled');
    });
}